/**
 * The 404 component
 */
import React from "react";
import PropTypes from "prop-types";
import { Container } from 'reactstrap';
import { Link } from "react-router-dom";

const FourOFour = (props, context) => {
  
  return (
    <div className='fourofour'>
      <Container>
        <div className='fourofour__content text-center'>
          <h1>{context.t("ForOFour.message")}</h1>
          <div className="fourofour__cta mt-4">
            <Link to={`/`} className="btn btn-primary">
              {context.t("ForOFour.button")}
            </Link>
          </div>
        </div>  
      </Container>          
    </div>
  );
}
/**  define proptype for the 'translation' function  */
FourOFour.contextTypes = {
  t: PropTypes.func
}
export default FourOFour;
