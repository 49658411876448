/**
 * Actions and ActionTypes definitions for the CMSBlock resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'

/** LIST cmsBlocks action-types */
const LIST = 'cmsBlocks/LIST';
const LIST_SUCCESS = 'cmsBlocks/LIST_SUCCESS';
const LIST_FAIL = 'cmsBlocks/LIST_FAIL';

/** SHOW cmsBlock action-types */
const SHOW = 'cmsBlocks/SHOW';
const SHOW_SUCCESS = 'cmsBlocks/SHOW_SUCCESS';
const SHOW_FAIL = 'cmsBlocks/SHOW_FAIL';

/** STORE cmsBlock action-type */
const STORE = 'cmsBlocks/STORE';
/** UNSTORE cmsBlock action-type */
const UNSTORE = 'cmsBlocks/UNSTORE';


/**
 * list cmsBlocks
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show cmsBlock
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);


/**
 * store cmsBlock
 */
const storeCMSBlock = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
/**
 * unstore cmsBlock
 */
const unstoreCMSBlock = createAction(UNSTORE);

/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  storeCMSBlock: storeCMSBlock,
  unstoreCMSBlock: unstoreCMSBlock
}
