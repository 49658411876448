/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import notificationsSaga from './notifications/sagas'
import { all } from 'redux-saga/effects'
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import sectionsSaga from './sections/sagas';
import checklistsSaga from './checklists/sagas';
import samplesSaga from './samples/sagas';
import xapisSaga from './xapis/sagas';
import cmsBlocksSaga from './cmsBlocks/sagas';

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
	sectionsSaga(),
	  checklistsSaga(),
	  samplesSaga(),
    xapisSaga(),
    cmsBlocksSaga()
  ])
}
