/**
 * The navbar container
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem } from 'reactstrap';
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { NavLink, Link } from 'react-router-dom'
import {setLanguage} from "redux-i18n";
import {isServer} from '../../redux/store'
import FlashAlert from '../FlashAlert';
import logo from '../../assets/images/logo-logoPrimary.svg'
import IconHome from '../../assets/icons/icon-home.svg'
import IconBauplan from '../../assets/icons/icon-bauplan.svg'
import IconWhoHelps from '../../assets/icons/icon-whohelps.svg'
import IconLaw from '../../assets/icons/icon-law.svg'
import IconParticipate from '../../assets/icons/icon-participate.svg'
import {
  isDirty,
  isPristine,
} from 'redux-form'

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  alertLink: state.notifications.link,
  alertOrigin: state.notifications.origin,
  lang: state.i18nState.lang,
  isFormDirty: isDirty('checklistForm')(state),
  isFormPristine: isPristine('checklistForm')(state),
 })
 /**
 * Binds dispatch method to the actions passed as props to the component
 */
 const mapDispatchToProps = dispatch => bindActionCreators({
     setLanguage: setLanguage,
     navigateTo: push
   },
   dispatch
 )

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
export class Navigationbar extends Component {

  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props 
   */
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  /** toggles the open state of the navbar */
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  /** handles all navigation links */
  handleNavigationAction = (path, event) => {
    event.preventDefault();
    this.props.navigateTo(path);
  }

  /** handles the change language event */
  handleI18nAction = (lang, event) => {
    event.preventDefault();
    this.props.setLanguage(lang);
  }


  render() {
    const { alertMessage, alertColor, alertTimeout, alertLink, alertOrigin, backTarget, isFormPristine, isFormDirty  } = this.props
    const smallDevice = !isServer ? window.matchMedia('(max-width: 991.98px)').matches : false;
    
    return (
      <div>
        <div style={this.props.style} className={classNames('navigationbar ', {'navigationbar--sticky': this.props.isSticky})}>
          <Navbar color="white" expand="sm">
            { (backTarget && smallDevice) && !isFormDirty &&
              <Link to={backTarget} className='navigationbar__back--mobile btn btn-link'>
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span className='sr-only'>{this.context.t("Zurück")}</span>
              </Link>
            }
            <NavbarBrand href="#" onClick={this.handleNavigationAction.bind(this, '/')}>
              <img src={logo} width="155" height="48" className="logo" alt="home link"/>
            </NavbarBrand>
            { (backTarget && !smallDevice) && !isFormDirty &&
              <Link to={backTarget} className='navigationbar__back--desktop btn btn-link'>
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>&nbsp;&nbsp;{this.context.t("Zurück")}</span>
              </Link>
            }
            
          </Navbar>
          { alertMessage && 
            <FlashAlert color={alertColor || 'success'} timeout={alertTimeout} origin={alertOrigin} link={alertLink}>{alertMessage}</FlashAlert>
          }
        </div>
        { !backTarget &&
          <div className='navigationbar__nav'>
            <Navbar color="white" expand="sm" role='navigation'>
              <Nav navbar className="navigationbar__nav__items">
                <NavItem>
                  <NavLink exact to='/' className='nav-link'>
                    <img src={IconHome} width="20" height="20" className="logo" alt=""/>
                    {this.context.t("Verstehen")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/bauplan' className='nav-link'>
                    <img src={IconBauplan} width="20" height="20" className="logo" alt=""/>
                    {this.context.t("Bauplan")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/wer-hilft' className='nav-link'>
                    <img src={IconWhoHelps} width="20" height="20" className="logo" alt=""/>
                    {this.context.t("Wer hilft?")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/mitmachen' className='nav-link'>
                    <img src={IconParticipate} width="20" height="20" className="logo" alt=""/>
                    {this.context.t("Mitmachen")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/impressum' className='nav-link'>
                    <img src={IconLaw} width="20" height="20" className="logo" alt=""/>
                    {this.context.t("Rechtliches")}
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        }
      </div>
    );
  }
}

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar)
