/**
 * Actions and ActionTypes definitions for the Checklist resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'

/** LIST checklists action-types */
const LIST = 'checklists/LIST';
const LIST_SUCCESS = 'checklists/LIST_SUCCESS';
const LIST_FAIL = 'checklists/LIST_FAIL';

/** SHOW checklist action-types */
const SHOW = 'checklists/SHOW';
const SHOW_SUCCESS = 'checklists/SHOW_SUCCESS';
const SHOW_FAIL = 'checklists/SHOW_FAIL';

/** CREATE checklist action-types */
const CREATE = 'checklists/CREATE';
const CREATE_SUCCESS = 'checklists/CREATE_SUCCESS';
const CREATE_FAIL = 'checklists/CREATE_FAIL';

/** UPDATE checklist action-types */
const UPDATE = 'checklists/UPDATE';
const UPDATE_SUCCESS = 'checklists/UPDATE_SUCCESS';
const UPDATE_FAIL = 'checklists/UPDATE_FAIL';

/** REMOVE checklist action-types */
const REMOVE = 'checklists/REMOVE';
const REMOVE_SUCCESS = 'checklists/REMOVE_SUCCESS';
const REMOVE_FAIL = 'checklists/REMOVE_FAIL';

/** STORE checklist action-type */
const STORE = 'checklists/STORE';
/** UNSTORE checklist action-type */
const UNSTORE = 'checklists/UNSTORE';


/**
 * list checklists
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show checklist
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create checklist
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update checklist
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * remove checklist
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store checklist
 */
const storeChecklist = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
/**
 * unstore checklist
 */
const unstoreChecklist = createAction(UNSTORE);

/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeChecklist: storeChecklist,
  unstoreChecklist: unstoreChecklist
}
