/**
 *  Reducer function for the xapis resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/xapis
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';

/** The article reducer initial state */
export const defaultState = {
  stored: true,
  home: null,
  loadingHome: false,
  loadedHome: false,
  homeError: null,
  relatedLinks: null,
  loadingRelatedLinks: false,
  loadedRelatedLinks: false,
  relatedLinksError: null,
  imprint: null,
  loadingImprint: false,
  loadedImprint: false,
  imprintError: null,
  bauplan: null,
  loadingBauplan: false,
  loadedBauplan: false,
  bauplanError: null,
  mitmachen: null,
  loadingMitmachen: false,
  loadedMitmachen: false,
  mitmachenError: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    [actions.loadHome]: (state) => {
      return {
        ...state,
        loadingHome: true,
        loadedHome: false,
        homeError: null,
      };
    },
    [actions.loadHomeSuccess]: (state, action) => {
      return {
        ...state,
        loadingHome: false,
        loadedHome: new Date(),
        home: action.payload
      };
    },
    [actions.loadHomeFail]: (state, action) => {
      return {
        ...state,
        loadingHome: false,
        loadedHome: false,
        homeError: action.payload.mess
      };
    },
    [actions.loadRelatedLinks]: (state) => {
      return {
        ...state,
        loadingRelatedLinks: true,
        loadedRelatedLinks: false,
        relatedLinksError: null,
      };
    },
    [actions.loadRelatedLinksSuccess]: (state, action) => {
      return {
        ...state,
        loadingRelatedLinks: false,
        loadedRelatedLinks: new Date(),
        relatedLinks: action.payload
      };
    },
    [actions.loadRelatedLinksFail]: (state, action) => {
      return {
        ...state,
        loadingRelatedLinks: false,
        loadedRelatedLinks: false,
        relatedLinksError: action.payload.mess
      };
    },
    [actions.loadImprint]: (state) => {
      return {
        ...state,
        loadingImprint: true,
        loadedImprint: false,
        imprintError: null,
      };
    },
    [actions.loadImprintSuccess]: (state, action) => {
      return {
        ...state,
        loadingImprint: false,
        loadedImprint: new Date(),
        imprint: action.payload
      };
    },
    [actions.loadImprintFail]: (state, action) => {
      return {
        ...state,
        loadingImprint: false,
        loadedImprint: false,
        imprintError: action.payload.mess
      };
    },
    [actions.loadBauplan]: (state) => {
      return {
        ...state,
        loadingBauplan: true,
        loadedBauplan: false,
        bauplanError: null,
      };
    },
    [actions.loadBauplanSuccess]: (state, action) => {
      return {
        ...state,
        loadingBauplan: false,
        loadedBauplan: new Date(),
        bauplan: action.payload
      };
    },
    [actions.loadBauplanFail]: (state, action) => {
      return {
        ...state,
        loadingBauplan: false,
        loadedBauplan: false,
        bauplanError: action.payload.mess
      };
    },
    [actions.loadMitmachen]: (state) => {
      return {
        ...state,
        loadingMitmachen: true,
        loadedMitmachen: false,
        mitmachenError: null,
      };
    },
    [actions.loadMitmachenSuccess]: (state, action) => {
      return {
        ...state,
        loadingMitmachen: false,
        loadedMitmachen: new Date(),
        mitmachen: action.payload
      };
    },
    [actions.loadMitmachenFail]: (state, action) => {
      return {
        ...state,
        loadingMitmachen: false,
        loadedMitmachen: false,
        mitmachenError: action.payload.mess
      };
    },
  },
  defaultState
);
