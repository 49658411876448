/**
 * Actions and ActionTypes definitions for managing the backup code. This code is retrieved on checklist.createSuccess action and saved here.
 * The backup_code will be sent as header in every other checklist action.
 * 
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 */
import { createAction } from 'redux-actions';

/** Store backup_code action-types */
const STORE_BACKUP_CODE = 'backupCode/STORE_BACKUP_CODE';

/**
 * Store backup code action
 */
const storeBackupCode = createAction(STORE_BACKUP_CODE);


/** exports all actions as default */
export default {
  storeBackupCode: storeBackupCode,
};
