/**
 * Actions and ActionTypes definitions for managing the popUps statuses (should only be shown on first load)
 * 
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 */
import { createAction } from 'redux-actions';

/** Set popUps action-types */
const SET_POPUP_SHOWN = 'popUps/SET_POPUP_SHOWN';

/**
 * Set popUps action
 */
const setPopUpShown = createAction(SET_POPUP_SHOWN);


/** exports all actions as default */
export default {
  setPopUpShown: setPopUpShown,
};
