/**
 *  Sagas for the xapi resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';

/** Worker Sagas */

/** Home Saga
 *  @description: connects to the `home` endpoint of the xapi on
 */
export function* loadHome() {
  let headers = buildHeaders()
  const lang = yield select(state => state.i18nState.lang);
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Xapis.getHome, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.loadHomeSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.loadHomeFail, payload: e});
  }
}

/** Related Links Saga
 *  @description: connects to the `related_links` endpoint of the xapi on
 */
export function* loadRelatedLinks() {
  let headers = buildHeaders()
  const lang = yield select(state => state.i18nState.lang);
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Xapis.getRelatedLinks, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.loadRelatedLinksSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.loadRelatedLinksFail, payload: e});
  }
}

/** Imprint Saga
 *  @description: connects to the `imprint` endpoint of the xapi on
 */
export function* loadImprint() {
  let headers = buildHeaders()
  const lang = yield select(state => state.i18nState.lang);
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Xapis.getImprint, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.loadImprintSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.loadImprintFail, payload: e});
  }
}

/** Bauplan Saga
 *  @description: connects to the `imprint` endpoint of the xapi on
 */
export function* loadBauplan() {
  let headers = buildHeaders()
  const lang = yield select(state => state.i18nState.lang);
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Xapis.getBauplan, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.loadBauplanSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.loadBauplanFail, payload: e});
  }
}

/** Mitmachen Saga
 *  @description: connects to the `imprint` endpoint of the xapi on
 */
export function* loadMitmachen() {
  let headers = buildHeaders()
  const lang = yield select(state => state.i18nState.lang);
  headers['Accept-Language'] = lang
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Xapis.getMitmachen, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.loadMitmachenSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.loadMitmachenFail, payload: e});
  }
}



/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* xapiSaga() {
  yield takeEvery(actions.loadHome, loadHome);
  yield takeEvery(actions.loadRelatedLinks, loadRelatedLinks);
  yield takeEvery(actions.loadImprint, loadImprint);
  yield takeEvery(actions.loadMitmachen, loadMitmachen);
  yield takeEvery(actions.loadBauplan, loadBauplan);
}
export default xapiSaga;
