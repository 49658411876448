/**
 * Actions and ActionTypes definitions for the Xapi resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */

import { createAction } from 'redux-actions';

/** Load home action-types */
const HOME = 'xapi/HOME';
const HOME_SUCCESS = 'xapi/HOME_SUCCESS';
const HOME_FAIL = 'xapi/HOME_FAIL';

/** Load related links action-types */
const RELATED_LINKS = 'xapi/RELATED_LINKS';
const RELATED_LINKS_SUCCESS = 'xapi/RELATED_LINKS_SUCCESS';
const RELATED_LINKS_FAIL = 'xapi/RELATED_LINKS_FAIL';

/** Load imprin action-types */
const IMPRINT = 'xapi/IMPRINT';
const IMPRINT_SUCCESS = 'xapi/IMPRINT_SUCCESS';
const IMPRINT_FAIL = 'xapi/IMPRINT_FAIL';

/** Load imprin action-types */
const BAUPLAN = 'xapi/BAUPLAN';
const BAUPLAN_SUCCESS = 'xapi/BAUPLAN_SUCCESS';
const BAUPLAN_FAIL = 'xapi/BAUPLAN_FAIL';

/** Load imprin action-types */
const MITMACHEN = 'xapi/MITMACHEN';
const MITMACHEN_SUCCESS = 'xapi/MITMACHEN_SUCCESS';
const MITMACHEN_FAIL = 'xapi/MITMACHEN_FAIL';

/**
 * Loads data for the home page
 */
const loadHomeSuccess = createAction(HOME_SUCCESS);
const loadHomeFail = createAction(HOME_FAIL);
const loadHome = createAction(HOME);

/**
 * Loads data for the related links page
 */
const loadRelatedLinksSuccess = createAction(RELATED_LINKS_SUCCESS);
const loadRelatedLinksFail = createAction(RELATED_LINKS_FAIL);
const loadRelatedLinks = createAction(RELATED_LINKS);

/**
 * Loads data for the related links page
 */
const loadImprintSuccess = createAction(IMPRINT_SUCCESS);
const loadImprintFail = createAction(IMPRINT_FAIL);
const loadImprint = createAction(IMPRINT);

/**
 * Loads data for the related links page
 */
const loadBauplanSuccess = createAction(BAUPLAN_SUCCESS);
const loadBauplanFail = createAction(BAUPLAN_FAIL);
const loadBauplan = createAction(BAUPLAN);

/**
 * Loads data for the related links page
 */
const loadMitmachenSuccess = createAction(MITMACHEN_SUCCESS);
const loadMitmachenFail = createAction(MITMACHEN_FAIL);
const loadMitmachen = createAction(MITMACHEN);

export default {
  loadHome: loadHome,
  loadHomeSuccess: loadHomeSuccess,
  loadHomeFail: loadHomeFail,
  loadRelatedLinks: loadRelatedLinks,
  loadRelatedLinksSuccess: loadRelatedLinksSuccess,
  loadRelatedLinksFail: loadRelatedLinksFail,
  loadImprint: loadImprint,
  loadImprintSuccess: loadImprintSuccess,
  loadImprintFail: loadImprintFail,
  loadMitmachen: loadMitmachen,
  loadMitmachenSuccess: loadMitmachenSuccess,
  loadMitmachenFail: loadMitmachenFail,
  loadBauplan: loadBauplan,
  loadBauplanSuccess: loadBauplanSuccess,
  loadBauplanFail: loadBauplanFail,
}
