export const translations = {
  'de': {
    'Schau dir diese Checkliste an:': 'Sehen Sie sich diese Liste an:',
    'Schließen': 'Schließen',
    'Zu meinen Listen hinzufügen': 'Speichern',
    'Liste löschen': 'Liste Löschen',
    'Liste duplizieren': 'Liste duplizieren',
    'Liste teilen': 'Liste teilen',
    'URL Kopiert': 'URL Kopiert',
    'checklist.template.hinweis': 'HINWEIS: Bitte klicken Sie auf “Speichern”, um die Vorlage auf der nächsten Seite zu bearbeiten und zu Ihren persönlichen Listen hinzuzufügen.',
    'Liste Löschen': 'Liste Löschen',
    'Soll diese Liste wirklich gelöscht werden?': 'Soll diese Liste wirklich gelöscht werden?',
    'Löschen': 'Löschen',
    'Neuen Eintrag hinzufügen': 'Neuen Eintrag hinzufügen',
    'Titel bearbeiten': 'Titel bearbeiten',
    'Titel bestätigen': 'Titel speichern',
    'Submit': 'Abschicken',
    'erledigt': 'erledigt',
    'offen': 'offen',
    'Eintrag bearbeiten': 'Eintrag bearbeiten',
    'Eintrag löschen': 'Eintrag löschen',
    'Eintrag nach Oben': 'Eintrag nach oben',
    'Eintrag nach Unten': 'Eintrag nach unten',
    'Eintrag bestätigen': 'Eintrag speichern',
    'Vorlage': 'Vorlage',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'Erstellen Sie eine neue Checkliste ohne Vorlage': 'Erstellen Sie eine neue persönliche Liste',
    'Einträge': 'Einträge',
    'Checklisten durchsuchen': 'Listen durchsuchen',
    'Haben Sie einen Backupcode?': 'Haben Sie einen Nutzercode?',
    'Backupcode Eingeben': 'Geben Sie hier Ihren Nutzercode ein.',
    'oder': 'oder',
    'Neue Liste aus Vorlage erstellen': 'REHADAT-Vorlagen',
    'Fahrplan': 'Fahrplan',
    'Hilfe': 'Hilfe',
    'Checklisten offline': 'Listen offline',
    'hinzufügen': 'hinzufügen',
    'Neue Liste': 'Neue Liste',
    'Backupcode': 'Nutzercode',
    'In Zwischenablage kopieren': 'In Zwischenablage kopieren',
    'Kopiert': 'Kopiert',
    'Checkliste bearbeiten': 'Liste bearbeiten',
    'Neue Inhalte sind verfügbar. Bitte aktualisieren Sie die Seite, um Inkonsistenzen zu vermeiden. Hier klicken um zu aktualisieren': 'Es sind neue Inhalte verfügbar. Bitte aktualisieren Sie die Seite, um Inkonsistenzen zu vermeiden. Hier klicken um zu aktualisieren',
    'Name': 'Name',
    'Description': 'Beschreibung',
    'Items.header': 'Element',
    'Items.header.offline': 'Offline Elemente',
    'LoadingContent.message': 'Die Inhalte werden geladen.',
    'Email': 'E-Mail',
    'Password': 'Passwort',
    'ForgotPassword': 'Passwort vergessen',
    'ForgotPassword.here': 'Hier',
    'Zurück': 'Zurück',
    'Verstehen': 'Verstehen',
    'Beispiele': 'Beispiele',
    'Wer hilft?': 'Wer hilft?',
    'Impressum': 'Impressum',
    'Offline Message': 'Sie sind offline',
    'NewPassword': 'Neues Passwort',
    'NewPasswordConfirmation': 'Neues Passwort bestätigen',
    'PasswordConfirmation': 'Passwort bestätigen',
    'Beispiele durchsuchen': 'Beispiele durchsuchen',
    'schließe': 'Schließen',
    'öffne': 'Öffnen',
    'Filter': 'Filter',
    'Unternehmensgröße': 'Unternehmensgröße',
    'Alle': 'Alle',
    'Vereinbarungstyp': 'Vereinbarungstyp',
    'Branche': 'Branche',
    'Beispiel öffnen': 'Beispiel öffnen',
    'Beispiele offline': 'Beispiele offline',
    'Haben Sie doch keinen Backupcode?': 'Haben Sie noch keinen Nutzercode?',
    'Kein Problem. Beim erstellen Ihrer ersten Checkliste erstellen wir für Sie einen.': 'Beim Speichern Ihrer ersten Liste erstellt die App automatisch einen, den Sie auf dieser Seite sehen werden.',
    'Mit diesem können Sie Ihre erstellten Listen wieder herstellen.': 'Mit diesem Code können Sie Ihre Listen auf verschiedenen Geräten wiederherstellen.',
    'Neue Liste ohne Backupcode erstellen': 'Erstellen und bearbeiten Sie Listen ohne einen Nutzercode.',
    'Neue Liste erstellen': 'Persönliche Liste erstellen',
    'Checkliste erstellt': 'Die Liste wurde erstellt.',
    'Die Liste kann jetzt bearbeitet werden': 'Sie können sie jetzt bearbeiten und speichern.',
    'Zur Liste': 'Zur Liste',
    'Fertig bearbeitet': 'Speichern',
    'ForOFour.message': 'Die Seite wurde nicht gefunden.',
    'ForOFour.button': 'Zur Startseite',
    'Home.header.offline': 'Offline',
    'Home.image.alt-tag': 'Ablauf einer Inklusionsvereinbarung: Akteure versammeln, Interne oder externe Hilfe suchen und einbeziehen, ausführliche Analyse der Ist-Situation, Zielvereinbarungen mit SMARTen Maßnahmen, Verantwortlichkeiten festlegen, Laufzeit definieren.',
    'Home.Tab1': 'Bausteine',
    'Home.Tab2': 'Gute Gründe',
    'Home.Tab3': 'Abgrenzung',
    'Imprint.header.offline': 'Impressum',
    'RelatedLinks.header.offline': 'Weitere Adressen',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Name': '',
    'Description': '',
    'Submit': 'Submit',
    'Items.header': 'Welcome To Bakerplate',
    'Items.header.offline': 'Welcome To Offline Bakerplate',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Forgot your password? Recover',
    'ForgotPassword.here': 'here',
    'NewPassword': 'New Password',
    'NewPasswordConfirmation': 'New Password Confirm',
    'PasswordConfirmation': 'Password Confirm',
    'Number': 'Number',
    'Text': 'Text',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Welcome To Bakerplate',
    'Home.header.offline': 'Welcome To Offline Bakerplate',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
