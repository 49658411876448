/**
 *  Reducer function for the samples resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/samples
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The samples reducer initial state */
export const defaultState = {
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The samples list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The samples list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of samples
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload.list,
        filterValues: action.payload.filterValues
      };
    },
    /** The samples list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The sample show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The sample show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested sample
     */
    [actions.showSuccess]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The sample show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    
    /** The store sample action. Saves the currently shown sample in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeSample]: (state) => {
      let newStoredSamples = Object.assign({}, state.stored)
      newStoredSamples[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredSamples,
        show: newShow
      };
    },
    /** The unstore sample action. Removes the currently shown sample from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the sample to unstore
     */
    [actions.unstoreSample]: (state, action) => {
      let newStoredSamples = Object.assign({}, state.stored)
      delete newStoredSamples[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredSamples,
        show: newShow
      };
    }
  },
  defaultState
);
