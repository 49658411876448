/**
 * Sagas for the section resource managment
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from 'redux-saga/effects'
import apiRequest from '../../utils/apiRequest';
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import { push } from 'connected-react-router';
import {isServer} from "../store";
import download from 'downloadjs';
import Swagger from 'swagger-client'
const API_URL = process.env.REACT_APP_API_SERVER || 'http://localhost:3000'

/** Worker Sagas */

/** List Saga
 *  @description: connects to the `index` endpoint to fetch a list of sections
 */
export function* list(action) {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedSections = yield select(state => state.sections.stored);
    Object.keys(storedSections).forEach(storedSectionIndex => {
      storedList.push(Object.assign({}, storedSections[storedSectionIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders()
    try {
      const payload = yield call(apiRequest, `/sections.json`, {headers: headers});
      yield put({type: actions.listSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}
/** Show Saga
 *  @description: connects to the `show` endpoint to fetch an section by id
 *  @param {number} action.payload the section id
 */
export function* show(action) {
  const resourceId = action.payload;
  const storedSections = yield select(state => state.sections.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedSections[resourceId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedSections[resourceId]
    });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders()
    try {
      const payload = yield call(apiRequest, `/sections/${resourceId}.json`, {headers: headers});
      yield put({type: actions.showSuccess, payload: payload});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}
/** Create Saga
 *  @description: connects to the `create` endpoint to create an section
 *  If the resource contains a file (values.picture) it will post a formdata object rather than a json.
 *  - redirects to the created section show page
 * @param {Object} action.payload the section object
 */
export function* create(action) {
  let headers = buildHeaders()
  let values = action.payload;
  let body = JSON.stringify(values)
  try {
    const payload = yield call(downloadRtf, `/sections/export_rtf`, {body, headers: headers, method: 'POST'}, "Geruest-Inklusionsvereinbarung.rtf");

    // const payload = yield call(apiRequest, `/sections/export_rtf`, { body, method: 'POST', headers });
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
}

/** Update Saga
 *  @description: connects to the `update` endpoint to update an section by id
 *  If the resource contains a file (values.picture) it will post a formdata object rather than a json.
 *  - redirects to the created section show page
 * @param {Object} action.payload the section object
 */
export function* update(action) {
  let headers = buildHeaders()
  let values = action.payload;
  delete values.user_id
  let body = JSON.stringify(values)
  if (values.picture) {
    body = new FormData();
    Object.keys(values).forEach(key => {
      if (key !== 'picture') {  // skip picture since its a file
        body.append(`section[${key}]`, values[key]);
      }
    })
    body.append("section[picture]", values.picture[0], values.picture[0].name); // add the file
    delete headers["Content-Type"]  // remove header
  }
  try {
    const payload = yield call(apiRequest, `/sections/${action.payload.id}`, { body, method: 'PUT', headers });
    yield put({type: actions.updateSuccess, payload: payload});
    yield put(push('/sections/' + payload.id));
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the `delete` endpoint to remove an section by id
 *  @param {Object} action.payload the id of the section to delete
 */
 export function* remove(action) {
  let headers = buildHeaders()
   try {
    const payload = yield call(apiRequest, `/sections/${action.payload.id}`, {
      method: 'DELETE',
      headers: headers,
    });
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an section is unstored
 */
export function* unstoreSection(action) {
  const image = yield select(state => state.sections.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cahe', err);
    
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* sectionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreSection, unstoreSection);
}

export default sectionsSaga;

// helper
function downloadRtf(_url, args, filename) {
  const request = {
    url: API_URL + _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  }
  return Swagger.http(request)
  .then(function(resp) {
    download(resp.data, filename, "text/richtext");
    return resp.data
  })
  .catch((err) => {
    console.log('ERROR IN rtf')
    console.log(err);
    throw err.response.obj;
  });
}
