/**
 *  Sagas for the sample resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './actions';
import {isServer} from "../store";
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';

/** Helper Functions */
function extractFilterValues(list) {
  let filterValues = {
    business_size: [],
    agreement_type: [],
    area_of_business: []
  }
  list.forEach(sample => {
    if (filterValues.business_size.indexOf(sample.business_size) < 0){
      if (sample.business_size)
        filterValues.business_size.push(sample.business_size)
    }
    if (filterValues.agreement_type.indexOf(sample.agreement_type) < 0){
      if (sample.agreement_type)
        filterValues.agreement_type.push(sample.agreement_type)
    }
    if (filterValues.area_of_business.indexOf(sample.area_of_business) < 0){
      if (sample.area_of_business)
        filterValues.area_of_business.push(sample.area_of_business)
    }
  })
  return filterValues;
}
/** Worker Sagas */

/** List Saga
 *  @description: connects to the getSamples operation
 */
export function* list() {
  
  let headers = buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Samples.getSamples, {}, {requestInterceptor: applyHeaders(headers)})
    const samplesList = payload.obj;
    const filterValues = extractFilterValues(samplesList)

    yield put({type: actions.listSuccess, payload: {filterValues: filterValues, list:samplesList}});
  } catch (e) {
    yield put({type: actions.listFail, payload: e});
  }
}

/** Show Saga
 *  @description: connects to the showSample operation
 *  @param {number} action.payload the sample id
 */
export function* show(action) {
  const sampleId = action.payload;
  const storedSamples = yield select(state => state.samples.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedSamples[sampleId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedSamples[sampleId]
    });
  } else { // else we are online -> we fetch
    let headers = buildHeaders();
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.Samples.showSample, {id: sampleId}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an sample is unstored
 */
export function* unstoreSample() {
  const image = yield select(state => state.samples.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* samplesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.unstoreSample, unstoreSample);
}
export default samplesSaga;
