/**
 *  Reducer function for the checklists resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/checklists
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The checklists reducer initial state */
export const defaultState = {
  // stored: {},
  list: [],
  templates: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The checklists list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The checklists list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of checklists
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload.checklists,
        templates: action.payload.templates
      };
    },
    /** The checklists list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The checklist show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The checklist show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested checklist
     */
    [actions.showSuccess]: (state, action) => {
      // if (state.stored[action.payload.id]) {
      //   action.payload.stored = true
      // }    
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The checklist show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    /** The checklist create action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    /** The checklist create action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the created checklist
     */
    [actions.createSuccess]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    /** The checklist create action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    /** The checklist update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    /** The checklist update action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the updated of checklists
     */
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    /** The checklist update action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload.mess
      };
    },
    /** The checklist remove action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    /** The checklist remove action success
     * @param {Object} state the current state of this reducer
     */
    [actions.removeSuccess]: (state) => {
      return {
        ...state,
        removing: false,
        removed: true,
      };
    },
    /** The checklist remove action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    /** The store checklist action. Saves the currently shown checklist in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeChecklist]: (state) => {
      let newStoredChecklists = Object.assign({}, state.stored)
      newStoredChecklists[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredChecklists,
        show: newShow
      };
    },
    /** The unstore checklist action. Removes the currently shown checklist from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the checklist to unstore
     */
    [actions.unstoreChecklist]: (state, action) => {
      let newStoredChecklists = Object.assign({}, state.stored)
      delete newStoredChecklists[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredChecklists,
        show: newShow
      };
    }
  },
  defaultState
);
