
/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import {i18nState} from "redux-i18n";
import { reducer as formReducer } from 'redux-form'
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import backupCode from "./backupCode/reducers";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import sections from './sections/reducers';
import cmsBlocks from './cmsBlocks/reducers';
import checklists from './checklists/reducers';
import samples from './samples/reducers';
import xapis from './xapis/reducers';
import popUps from './popUps/reducers';

/** exports all reducers through the combineReducers function */
export default (history) => combineReducers({
  router: connectRouter(history),
  i18nState,
  servWork,
  notifications,
  backupCode,
  popUps,
  // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
  // new scaffolded reducers
	sections,
  cmsBlocks,
  checklists,
	samples,
	xapis,
  form: formReducer
});
