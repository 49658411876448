/**
 *  Reducer function for the popUps management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/servWork
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';

/** The service worker reducer initial state */
export const defaultState = {
  stored: true,
  checklistPopUpShown: false,
  homePopUpShown: false,
};


export default handleActions(
  {
    /** The set popupshown action. Sets the selected popUp as shown
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the type of popup
     */
    [actions.setPopUpShown]: (state, action) => {
      let popUpType = action.payload;
      let checklistPopUpShown = popUpType === 'checklist' ? true : state.checklistPopUpShown
      let homePopUpShown = popUpType === 'home' ? true : state.homePopUpShown
      return {
        ...state,
        checklistPopUpShown: checklistPopUpShown,
        homePopUpShown: homePopUpShown
      };
    },
  },
  defaultState
);
