/**
 *  Reducer function for the backupCode management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/servWork
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';

/** The service worker reducer initial state */
export const defaultState = {
  stored: true,
  lastCodeSavedAt: null,
  backupCode: null
};


export default handleActions(
  {
    /** The store backup code action. Saves the backup code data in the store
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the backup code data
     */
    [actions.storeBackupCode]: (state, action) => {
      return {
        ...state,
        backupCode: action.payload,
        lastCodeSavedAt: new Date()
      };
    },
  },
  defaultState
);
