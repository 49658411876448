/**
 *  Reducer function for the cmsBlocks resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/cmsBlocks
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './actions';


/** The cmsBlocks reducer initial state */
export const defaultState = {
  stored: {},
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The cmsBlocks list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The cmsBlocks list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of cmsBlocks
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    /** The cmsBlocks list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The cmsBlock show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The cmsBlock show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested cmsBlock
     */
    [actions.showSuccess]: (state, action) => {
      if (state.stored[action.payload.id]) {
        action.payload.stored = true
      }    
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The cmsBlock show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    /** The store cmsBlock action. Saves the currently shown cmsBlock in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeCMSBlock]: (state) => {
      let newStoredCMSBlocks = Object.assign({}, state.stored)
      newStoredCMSBlocks[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredCMSBlocks,
        show: newShow
      };
    },
    /** The unstore cmsBlock action. Removes the currently shown cmsBlock from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the cmsBlock to unstore
     */
    [actions.unstoreCMSBlock]: (state, action) => {
      let newStoredCMSBlocks = Object.assign({}, state.stored)
      delete newStoredCMSBlocks[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredCMSBlocks,
        show: newShow
      };
    }
  },
  defaultState
);
