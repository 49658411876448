/** 
 * The Root container
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';
import Navbar from '../components/Navbar'
import OfflineBar from '../components/OfflineBar'
import Loadable from 'react-loadable';
import FourOFour from './FourOFour';

/**
 * Loadable import for Home container
 */
const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  loading: () => null,
  modules: ['Home']
});

/**
 * Loadable import for RelatedLinks container
 */
const RelatedLinks = Loadable({
  loader: () => import(/* webpackChunkName: "RelatedLinks" */ './RelatedLinks'),
  loading: () => null,
  modules: ['RelatedLinks']
});

/**
 * Loadable import for Imprint container
 */
const Imprint = Loadable({
  loader: () => import(/* webpackChunkName: "Imprint" */ './Imprint'),
  loading: () => null,
  modules: ['Imprint']
});


// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports
// Loadable import for ChecklistDisplay container
// const ChecklistDisplay = Loadable({
//   loader: () => import(/* webpackChunkName: "ChecklistDisplay" */ './ChecklistDisplay'),
//   loading: () => null,
//   modules: ['ChecklistDisplay']
// });

// // Loadable import for Checklist container
// const Checklist = Loadable({
//   loader: () => import(/* webpackChunkName: "Checklist" */ './Checklist'),
//   loading: () => null,
//   modules: ['Checklist']
// });
// // Loadable import for ChecklistConfirmed container
// const ChecklistConfirmed = Loadable({
//   loader: () => import(/* webpackChunkName: "ChecklistConfirmed" */ './ChecklistConfirmed'),
//   loading: () => null,
//   modules: ['ChecklistConfirmed']
// });
// // Loadable import for Checklist container
// const Backupcode = Loadable({
//   loader: () => import(/* webpackChunkName: "Checklist" */ './Backupcode'),
//   loading: () => null,
//   modules: ['Backupcode']
// });
// Loadable import for Participate container
const Participate = Loadable({
  loader: () => import(/* webpackChunkName: "Participate" */ './Participate'),
  loading: () => null,
  modules: ['Participate']
});

// Loadable import for Modules container
const Sections = Loadable({
  loader: () => import(/* webpackChunkName: "Sections" */ './Sections'),
  loading: () => null,
  modules: ['Sections']
});

/**
 * The Root container used as entry point for the react-router. 
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {
  
  render() {
    let backTarget = null;
    if (this.props.location.pathname.includes('/edit')) {
      backTarget=this.props.location.pathname.split('/edit')[0];
    }
    else if (this.props.location.pathname.includes('/checklisten/')) {
      backTarget=this.props.location.pathname.split('/checklisten/')[0]+'/checklisten';
    }
    else if (this.props.location.pathname.includes('/beispiele/')) {
      backTarget=this.props.location.pathname.split('/beispiele/')[0]+'/beispiele';
    }
    
    return (
      <StickyContainer id="Root">
        <OfflineBar />
        <Sticky topOffset={1} disableCompensation>
          { ({style, isSticky}) => <Navbar style={{...style}} isSticky={isSticky} backTarget={backTarget}/>}
        </Sticky>
        <div id="content">
          <Switch>
            <Route path={"/"} exact component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/wer-hilft" component={RelatedLinks} />
            <Route exact path="/impressum" component={Imprint} />
            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}
						{/* <Route exact path={'/checklisten/confirmed'} component={ ChecklistConfirmed} />
						<Route exact path={'/checklisten/:slug/edit'} component={ ChecklistDisplay} />
            <Route exact path={'/checklisten/backupcode'} component={ Backupcode } />
						<Route exact path={'/checklisten/:slug?'} component={ Checklist } /> */}
						<Route exact path="/bauplan" component={ Sections } />
						<Route exact path="/mitmachen" component={ Participate } />
            <Route component={FourOFour} />
          </Switch>
        </div>
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Root)
);