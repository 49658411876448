/**
 *  Sagas for the checklist resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import actions from './actions';
import backupCodeActions from '../backupCode';
import notificationsActions from '../notifications';
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';
import { push, goBack } from 'connected-react-router';

/** Helpers */
function buildHeaders(backupCode) {
  if (backupCode) {
    return {
    'Content-Type': 'application/json',
    'backup_code': backupCode,
    }
  }
  return {
    'Content-Type': 'application/json'
  }
}
/** Worker Sagas */

/** List Saga
 *  @description: connects to the getChecklists operation
 */
export function* list(action) {
  
  let backupCode = false
  if (action.payload && action.payload.code) {
    backupCode = action.payload.code;
  } else {
    backupCode = yield select(state => state.backupCode.backupCode);
  }
  let headers = buildHeaders(backupCode);
  try {
    const SwaggerClient = yield call(apiClient)
    const payload = yield call(SwaggerClient.apis.Checklists.getChecklists, {}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.listSuccess, payload: payload.obj});
    if (action.payload && action.payload.code){
      if (payload.obj.checklists && payload.obj.checklists.length){
        yield put({type: backupCodeActions.storeBackupCode, payload: action.payload.code})
        yield put(push('/checklisten'));
      } else {
        let alertOptions = { message: 'Sorry, no checklists found for this backup code', color: 'warning', origin: 'backupcode', timeout: 3 }
        yield put({type: notificationsActions.showAlert, payload: alertOptions})
      }
    }
    

  } catch (e) {
    yield put({type: actions.listFail, payload: e});
  }
  
}

/** Show Saga
 *  @description: connects to the showChecklist operation
 *  @param {number} action.payload the checklist id
 */
export function* show(action) {
  const checklistSlug = action.payload;

    const backupCode = yield select(state => state.backupCode.backupCode);
    let headers = buildHeaders(backupCode);
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.Checklists.showChecklist, {slug: checklistSlug}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
}

/** Create Saga  
 *  @description: connects to the createChecklist operation.
 *  @param {Object} action.payload the checklist to create
 */
export function* create(action) {
  const backupCode = yield select(state => state.backupCode.backupCode);
  let headers = buildHeaders(backupCode)
  let values = {...action.payload};
  try {
    const SwaggerClient = yield call(apiClient)
    let payload = yield call(SwaggerClient.apis.Checklists.createChecklist, {slug: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.createSuccess, payload: payload.obj});
    yield put({type: backupCodeActions.storeBackupCode, payload: payload.obj.backup_code})
    yield put(push('/checklisten/confirmed'));
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
 }


/** Update Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateChecklist operation. If checklist contains a picture also connects to the uploadChecklist operation
 *  @param {Object} action.payload the checklist to update
 */
export function* update(action) {
  const backupCode = yield select(state => state.backupCode.backupCode);
  let headers = buildHeaders(backupCode);
  let values = {...action.payload};
  try {
    const SwaggerClient = yield call(apiClient)
    let payload = yield call(SwaggerClient.apis.Checklists.updateChecklist, {slug: values.checklist.slug, checklist: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.updateSuccess, payload: payload.obj});
    if (action.payload.goBack) {
      yield put(goBack());
    }
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the deleteChecklist operation.
 *  @param {Object} action.payload the id of the checklist to delete
 */
export function* remove(action) {
    const backupCode = yield select(state => state.backupCode.backupCode);
    let headers = buildHeaders(backupCode);
  try {
    const SwaggerClient = yield call(apiClient)
    let payload = yield call(SwaggerClient.apis.Checklists.deleteChecklist, {slug: action.payload}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
    yield put(push('/checklisten'));
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an checklist is unstored
 */
export function* unstoreChecklist() {
  const image = yield select(state => state.checklists.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* checklistsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreChecklist, unstoreChecklist);
}
export default checklistsSaga;
