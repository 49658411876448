/**
 *  Sagas for the cmsBlock resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 * 
 * TODO: update and create are not working through swagger client cause we dont know how to upload a picture along an id
 */

import { call, put, takeEvery, select } from 'redux-saga/effects'
import actions from './actions';
import {isServer} from "../store";
import apiClient, {applyHeaders} from '../../utils/apiSwaggerRequest';

/** Helpers */
function buildHeaders(backupCode) {
  if (backupCode) {
    return {
    'Content-Type': 'application/json',
    'backup_code': backupCode,
    }
  }
  return {
    'Content-Type': 'application/json'
  }
}
/** Worker Sagas */

/** List Saga
 *  @description: connects to the getCMSBlocks operation
 */
export function* list(action) {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedCMSBlocks = yield select(state => state.cmsBlocks.stored);
    Object.keys(storedCMSBlocks).forEach(storedCMSBlockIndex => {
      storedList.push(Object.assign({}, storedCMSBlocks[storedCMSBlockIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else { 
    /**  else we are online -> we fetch */
    let backupCode = yield select(state => state.backupCode.backupCode);
    let headers = buildHeaders(backupCode);
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.CmsBlocks.getCMSBlocks, {}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.listSuccess, payload: payload.obj});

    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}

/** Show Saga
 *  @description: connects to the showCMSBlock operation
 *  @param {number} action.payload the cmsBlock id
 */
export function* show(action) {
  const cmsBlockSlug = action.payload;
  const storedCMSBlocks = yield select(state => state.cmsBlocks.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedCMSBlocks[cmsBlockSlug]) {
    yield put({
      type: actions.showSuccess,
      payload: storedCMSBlocks[cmsBlockSlug]
    });
  } else { // else we are online -> we fetch
    const backupCode = yield select(state => state.backupCode.backupCode);
    let headers = buildHeaders(backupCode);
    try {
      const SwaggerClient = yield call(apiClient)
      const payload = yield call(SwaggerClient.apis.CmsBlocks.showCMSBlock, {slug: cmsBlockSlug}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}


/** Unstore Saga
 *  @description: handles the cache cleaning when an cmsBlock is unstored
 */
export function* unstoreCMSBlock() {
  const image = yield select(state => state.cmsBlocks.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* cmsBlocksSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.unstoreCMSBlock, unstoreCMSBlock);
}
export default cmsBlocksSaga;
