/**
 * Actions and ActionTypes definitions for the Sample resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'

/** LIST samples action-types */
const LIST = 'samples/LIST';
const LIST_SUCCESS = 'samples/LIST_SUCCESS';
const LIST_FAIL = 'samples/LIST_FAIL';

/** SHOW sample action-types */
const SHOW = 'samples/SHOW';
const SHOW_SUCCESS = 'samples/SHOW_SUCCESS';
const SHOW_FAIL = 'samples/SHOW_FAIL';


/** STORE sample action-type */
const STORE = 'samples/STORE';
/** UNSTORE sample action-type */
const UNSTORE = 'samples/UNSTORE';


/**
 * list samples
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show sample
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * store sample
 */
const storeSample = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
/**
 * unstore sample
 */
const unstoreSample = createAction(UNSTORE);

/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  storeSample: storeSample,
  unstoreSample: unstoreSample
}
